<template>
  <div class="main">
    <!-- section mission -->
    <section class="section-full">
      <div class="box">
            <h1 class="title-about" v-html="$t('mission.title')"></h1>
            <p class="intro" v-html="$t('mission.text')"></p>
            <!-- ITEM 1 -->
            <div class="mission-item">
                <div class="col-25">
                    <img class="mission-img" alt="mission" src="./../assets/icon-about-1.png" />
                </div>
                <div class="col-75">
                    <h2 v-html="$t('mission.one.title')"></h2>
                    <p v-html="$t('mission.one.text1')"></p>
                    <p v-html="$t('mission.one.text2')"></p>
                </div>
            </div>
            <!-- ITEM 2 -->
            <div class="mission-item">
                <div class="col-25">
                    <img class="mission-img" alt="mission" src="./../assets/icon-about-2.png" />
                </div>
                <div class="col-75">
                    <h2 v-html="$t('mission.two.title')"></h2>
                    <p v-html="$t('mission.two.text1')"></p>
                    <p v-html="$t('mission.two.text2')"></p>
                </div>
            </div>
            <!-- ITEM 3 -->
            <div class="mission-item">
                <div class="col-25">
                    <img class="mission-img" alt="mission" src="./../assets/icon-about-3.png" />
                </div>
                <div class="col-75">
                    <h2 v-html="$t('mission.three.title')"></h2>
                    <p v-html="$t('mission.three.text1')"></p>
                </div>
            </div>
            <!-- ITEM 4 -->
            <div class="mission-item">
                <div class="col-25">
                    <img class="mission-img" alt="mission" src="./../assets/icon-about-4.png" />
                </div>
                <div class="col-75">
                    <h2 v-html="$t('mission.four.title')"></h2>
                    <p v-html="$t('mission.four.text1')"></p>
                    <p v-html="$t('mission.four.text2')"></p>
                    <p v-html="$t('mission.four.text3')"></p>
                </div>
            </div>
        </div>
    </section>
    <!-- section team -->
    <section class="section-team">
      <div class="box">
        <h2>{{$t("about.title4")}}</h2>
        <team-item 
          v-for="(item, idx) in teams"
          :key="idx"
          :team="item"
        >
        </team-item>
      </div>
    </section>

  </div>
</template>

<script>
import TeamItem from "./../components/TeamItem.vue";

export default {
  name: "About",
  components : { TeamItem },
  data() {
    return {
      teams : []
    }
  },
  methods : {
    getData() {
      this.teams = [
      {
          img : require("@/assets/teams/papillon.png"),
          name : "Nicolas Papillon",
          job : this.$t("teams.papillon.job"),
          desc : this.$t("teams.papillon.desc"),
          order : 1
        },
        // {
        //   img : require("@/assets/teams/reynaud.png"),
        //   name : "François-Henri Reynaud",
        //   job : this.$t("teams.reynaud.job"),
        //   desc : this.$t("teams.reynaud.desc"),
        //   order : 2
        // },
        {
          img : require("@/assets/teams/droulout.jpg"),
          name : "Thomas Droulout",
          job : this.$t("teams.droulout.job"),
          desc : this.$t("teams.droulout.desc"),
          order : 3
        },
        // {
        //   img : require("@/assets/teams/Beyer.png"),
        //   name : "Nikolaus Beyer",
        //   job : this.$t("teams.beyer.job"),
        //   desc : this.$t("teams.beyer.desc"),
        //   order : 4
        // },
        // {
        //   img : require("@/assets/teams/Vazquez.png"),
        //   name : "Nathalie Vazquez",
        //   job : this.$t("teams.vazquez.job"),
        //   desc : this.$t("teams.vazquez.desc"),
        //   order : 5
        // },
      ]
    }
  },
  mounted() {
    this.getData();
  },
  watch : {
    '$i18n.locale' : function() {
      this.getData();
    }
  }
}
</script>

<style scoped lang="scss">
    p {
        font-size:18px;
    }

    .title-about {
      text-align: center;
      margin: 40px 0 40px 0;
      color: #fff;
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 400;
    }

    .mission-item {
        display: flex;
        align-items: center;
        margin:80px 0;
    }

    .mission-img {
        width: 90%;
        max-width: 200px;
    }

    .mission-item h2 {
        color:$mainColor;
        text-align: left;
        text-transform: uppercase;
        font-size:25px;
        font-weight: 400;
        margin-bottom:40px;
    }

    .col-25 {
        display: flex;
        justify-content: center;
    }

    .col-75 {
        border-left: 3px solid $mainColor;
        padding-left:20px;
    }

    @media screen and (max-width:768px){
        .mission-item {
            flex-direction: column;
            margin: 60px 0;
        }

        .mission-img {
            // margin-bottom: 20px;
            margin-top: 50px;
            margin-bottom: 70px;
        }

        .col-75 {
            padding:0;
            border:none;
        }

        p {
            text-align: justify;
        }
    }
</style>
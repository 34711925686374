<template>
    <div :class="['team-item', { first : team.order === 1}]">
        <div class="row">
            <div class="col-25">
                <img :src="team.img" :alt="team.name" class="avatar" />
            </div>
            <div class="col-75">
                <h3>{{ team.name }}</h3>
                <p class="job" v-html="team.job"></p>
                <p class="desc" v-html="team.desc"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "TeamItem",
    props : ['team']
}
</script>

<style scoped lang="scss">

    .col-25, .col-75 {
        padding:40px 0;
    }

    .col-75 {
        border-top:1px solid $mainColor;
    }

    .col-25 {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .avatar {
        margin-right:20%;
        width:115px;
        height:auto;
    }

    .first .col-75 {
        border-top:none;
    }

    h3 {
        color:#fff;
        font-weight:700;
        font-size:20px;
        margin:0;
        line-height:1.5em;
    }

    p.job {
        color:#fff;
        font-weight:300;
        font-style:italic;
        font-size:18px;
        margin:5px 0;
        line-height:1.5em;
    }

    p.desc {
        font-size:15px;
        text-align: justify;
        color:#fff;
        line-height: 1.5em;
        margin:0;
        font-weight: 300;
    }

    @media screen and (max-width:768px) {

        .col-25 {

            justify-content: flex-end;
            align-items: center;
            border-top:1px solid $mainColor;
            padding-bottom: 0px;
        }

        .col-75 {
            border-top:none;
            padding-top: 100px;
            padding-bottom: 100px;

        }

        .first .col-25 {
            border-top:none;
        }

        .avatar {
            margin:0 auto;
        }

    }
</style>